import React, { useEffect, useState } from "react";
import "./aboutus.css";
import Loader from "../../Components/Loader";
import AboutHead from "./AboutHead";
import ChooseUs from "./WhyChooseUsCards";
import Experience from "./Experience";
import Aboutus from "../../Components/Aboutus";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>About Us - JR Signage</title>
        <meta
          name="description"
          content="JR Signage is a leading signage manufacturer in Bangalore, specializing in custom 3D LED, acrylic, neon signs, flex banners, and more."
        />
        <meta
          name="keywords"
          content="Custom signage Bangalore, signage manufacturers Bangalore, 3D LED signs Bangalore, acrylic letter signs, neon signs Bangalore, flex banners Bangalore, outdoor signage Bangalore, branding signage, digital signage Bangalore, best signage company Bangalore."
        />
       
      </Helmet>
      <AboutHead />

      <div className="about-section" id="aboutus">
        <Experience />
        <Aboutus />
        <ChooseUs />
      </div>
    </>
  );
};

export default AboutUs;
