import React from "react";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Row className="my-5">
        <Col>
          <h1 className="text-center">Privacy Policy</h1>
          <Card>
            <Card.Body>
              <h4>Introduction</h4>
              <p>
                Welcome to our Privacy Policy page. Your privacy is of paramount
                importance to us. This document outlines how we collect, use,
                and protect your personal data.
              </p>

              <h4>Information We Collect</h4>
              <p>
                We collect personal information including your name, email
                address, and contact information.
              </p>

              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How We Use Your Information
                  </Accordion.Header>
                  <Accordion.Body>
                    We use your data to improve our services, provide customer
                    support, and communicate with you.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Your Rights</Accordion.Header>
                  <Accordion.Body>
                    You have the right to access, update, or delete your
                    personal information.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <h4 className="mt-3">Data Security</h4>
              <p>
                We use industry-standard security measures to protect your
                information from unauthorized access.
              </p>

              <h4 className="mt-3">Contact Us</h4>
              <p>
                If you have any questions regarding our privacy policy, feel
                free to contact us at{" "}
                <a href="mailto:jrsignage2420@gmail.com">
                  jrsignage2420@gmail.com
                </a>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
