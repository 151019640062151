import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaWhatsapp,
} from "react-icons/fa";
import "./ContactFooter.css";
import logo from "../../assets/logoJr.png";
const ContactFooter = () => {
  return (
    <footer className="bg-dark text-light  pb-4 pt-5 " id="contactus">
      <Container>
        <Row>
          <Col
            md={4}
            xs={12}
            className="d-flex flex-column text-md-start text-center mb-3 mb-md-0"
          >
            <h3 className="mb-4">Address</h3>

            <a
              href="https://maps.app.goo.gl/sJzGqjZZcSK8Z14A7"
              className="mb-2 footer-links"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaMapMarkerAlt className="footer-icons fs-5" />
              Chairman Street 1st Cross Rd, Ramaswamipalya, Maruthi Sevanagar,
              Bengaluru, Karnataka 560033
            </a>

            <a href="tel:+918073320068" className="mb-2 footer-links ">
              <FaPhoneAlt className="footer-icons fs-5" />
              +918073320068
            </a>

            <a
              href="mailto:jrsignage2420@gmail.com"
              className="mb-2 footer-links "
            >
              <FaEnvelope className="footer-icons fs-5" />
               jrsignage2420@gmail.com
            </a>

            <a
              href="https://wa.me/8073320068"
              className="mb-2 footer-links "
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="footer-icons fs-5" />
              +918073320068
            </a>
          </Col>
          {/* Logo Section */}
          <Col md={4} xs={12} className="text-center mb-3 mb-md-0">
            <h3>JR Signage</h3>
            <img
              src={logo}
              alt="Company Logo"
              style={{
                display: "block",
                margin: "0 auto",
                width: "fit-content",
                maxWidth: "200px",
              }}
            />
          </Col>
          {/* Map Section */}
          <Col md={4} xs={12} className="text-center text-md-start">
            <h3>Find Us</h3>
            <div className="border border-secondary rounded">
              <iframe
                src="https://www.google.com/maps/embed?pb=!4v1737471209078!6m8!1m7!1snLBDVbA8IsYbcmONs4UlMg!2m2!1d13.01036127388805!2d77.63596908441669!3f156.15330708661418!4f-23.026771653543307!5f0.4000000000000002"
                title="Google Map showing our office location"
                className="w-100 mb-n2"
                style={{ height: "200px", border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Col>
        </Row>

        {/* Copyright Section */}
      </Container>
      <Row className="mt-5 border-top border-secondary py-2">
        <Col className="">
          <p className="text-white-50  text-center">
            Copyright © <span className="text-white">2024 Jr Signage,</span> All
            Rights Reserved.
          </p>
          <div className="text-center ">
            <a href="/terms-conditions " className="text-white-50 ">
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href="/privacy-policy" className="text-white-50 ">
              Privacy Policy
            </a>
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default ContactFooter;
