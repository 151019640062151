import { Container, Row, Col } from "react-bootstrap";
import "./ServiceList.css";
import ServiceScroll from "../ServiceScrool";
import ServiceHead from "../ServiceHead";
import CommonService from "../CommonService";
import { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import ledFlex from "../../assets/IMAGES/Archive/flexface.jpg";
import radium from "../../assets/IMAGES/Archive/radiumsignwork.jpg";
import ledboard from "../../assets/IMAGES/Archive/led board.jpg";
import companybrand from "../../assets/IMAGES/companybranding.jpg";
import woodenart from "../../assets/IMAGES/Archive/wooden.jpg";
import acpsign from "../../assets/IMAGES/Archive/acpsign.jpg";
import ledscroll from "../../assets/scrollImages/ledBoard.jpg";
import radiumscroll from "../../assets/scrollImages/radium.jpg";
import companybrandcroll from "../../assets/scrollImages/companybrand.jpg";
import acpsigncroll from "../../assets/scrollImages/acpBoard.jpg";
import woodenartcroll from "../../assets/scrollImages/woodenart.jpg";
import ledexistcroll from "../../assets/scrollImages/exsist.jpg";
import { Helmet } from "react-helmet-async";

function LedService() {
  const ledServiceData = [
    {
      title: "LED Board",
      imgSrc: ledboard,
      points: [
        {
          head: "Enhanced Visibility",
          text: "Bright and eye-catching displays that easily grab attention.",
        },
        {
          head: "Dynamic Content",
          text: "Quickly update messages, promotions, and ads with ease.",
        },
        {
          head: "Energy Efficient",
          text: "Save on power costs with eco-friendly LED technology.",
        },
        {
          head: "Long-Lasting",
          text: "Durable, weather-resistant, and ideal for indoor and outdoor use.",
        },
      ],
    },
    {
      title: "ACP Signboard",
      imgSrc: acpsign,
      points: [
        {
          head: "Weather Resistant",
          text: "Built to withstand tough outdoor conditions.",
        },
        {
          head: "Sleek Look",
          text: "Modern finish with customizable colors and styles.",
        },
        {
          head: "Easy to Install",
          text: "Lightweight and convenient setup.",
        },
        {
          head: "Affordable",
          text: "Cost-effective solution with low maintenance.",
        },
      ],
    },
    {
      title: "Wooden Art Work",
      imgSrc: woodenart,
      points: [
        {
          head: "Unique Appeal",
          text: "Adds elegance and a distinctive charm to interiors.",
        },
        {
          head: "Customizable",
          text: "Fits various themes and styles to enhance ambiance.",
        },
        {
          head: "Eco-Friendly",
          text: "Made from sustainable wood sources.",
        },
        {
          head: "Timeless Beauty",
          text: "Durable, maintaining beauty over time.",
        },
      ],
    },
    {
      title: "Company Branding",
      imgSrc: companybrand,
      points: [
        {
          head: "Strong Identity",
          text: "Consistent branding for a professional image.",
        },
        {
          head: "Clear Values",
          text: "Showcase your mission and values effectively.",
        },
        {
          head: "Engaging Experience",
          text: "Create a memorable experience for customers.",
        },
        {
          head: "Emotional Connection",
          text: "Connect with customers through storytelling.",
        },
      ],
    },
    {
      title: "Radium Sign Work",
      imgSrc: radium,
      points: [
        {
          head: "Premium Material",
          text: "Made with high-quality, durable aluminum.",
        },
        {
          head: "Custom Finishes",
          text: "Available in various finishes to match your brand.",
        },
        {
          head: "Customizable Shapes",
          text: "Create intricate designs to fit your style.",
        },
        {
          head: "Color Options",
          text: "Choose from various colors or keep a natural finish.",
        },
      ],
    },
    {
      title: "LED Exit Sign",
      imgSrc: ledFlex,
      points: [
        {
          head: "Flexible Design",
          text: "Made from durable, stretchable PVC.",
        },
        {
          head: "Weather Resistant",
          text: "Built for all weather conditions.",
        },
        {
          head: "Versatile Use",
          text: "Ideal for storefronts, billboards, and signage.",
        },
        {
          head: "Custom Graphics",
          text: "High-quality graphics for personalized branding.",
        },
      ],
    },
  ];

  const services = [
    { serviceName: "LED Board", serviceImage: ledscroll },
    { serviceName: "ACP Signboard", serviceImage: acpsigncroll },
    { serviceName: "Wooden Art Work", serviceImage: woodenartcroll },
    { serviceName: "Company Branding", serviceImage: companybrandcroll },
    { serviceName: "Radium Sign Work", serviceImage: radiumscroll },
    {
      serviceName: "LED Exists Sign",
      serviceImage: ledexistcroll,
    },
  ];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>LED Services in Bangalore | JR Signage</title>
        <meta
          name="description"
          content="JR Signage offers premium LED services in Bangalore, specializing in 3D LED signs, LED boards, and custom lighting solutions for businesses."
        />
        <meta
          name="keywords"
          content="LED signage Bangalore, 3D LED signs, LED board makers, LED lighting solutions, custom LED signage, outdoor LED signs, indoor LED signage, LED sign services, affordable LED signs, best LED signage company"
        />
      </Helmet>
      <ServiceHead />
      <Container className="mt-5">
        <Row>
          <Col>
            <ServiceScroll
              services={services}
              title="Our LED "
              customClass="homepage-service-scroll"
            />
          </Col>
        </Row>
        <CommonService serviceData={ledServiceData} serviceTitle="" />
      </Container>
    </>
  );
}

export default LedService;
