import { Container, Row, Col } from "react-bootstrap";
import "./ServiceList.css";
import ServiceScroll from "../ServiceScrool";
import ServiceHead from "../ServiceHead";
import CommonService from "../CommonService";
import { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import ledsign from "../../assets/IMAGES/Archive/ledsign.jpeg";
import nonlite from "../../assets/IMAGES/Archive/nonlite.jpeg";
import images from "../../assets/IMAGES/Archive/2D&3D.jpeg";
import Brassstainless from "../../assets/IMAGES/Archive/Brass&stainless.jpeg";
import GlowSignBoard from "../../assets/IMAGES/Archive/GlowSignBoard.jpeg";
import ledsignscroll from "../../assets/scrollImages/ledBoard.jpg";
import nonlitescroll from "../../assets/scrollImages/nonlite.jpg";
import twodscroll from "../../assets/scrollImages/2dletters.jpg";
import Brassstainlessscroll from "../../assets/scrollImages/bras_Stainless.jpg";
import GlowSignBoardscroll from "../../assets/scrollImages/glowsign.jpg";
import { Helmet } from "react-helmet-async";

function SigBoardService() {
  const signBoardServiceData = [
    {
      title: "LED Sign Boards",
      imgSrc: ledsign,
      points: [
        {
          head: "High Visibility",
          text: "Bright and eye-catching, perfect for attracting attention.",
        },
        {
          head: "Energy-Efficient",
          text: "Long-lasting and consumes less power.",
        },
        {
          head: "Customizable",
          text: "Various sizes and designs to suit different needs.",
        },
        {
          head: "Durable Construction",
          text: "Made from robust materials to withstand outdoor conditions.",
        },
      ],
    },
    {
      title: "Non-Lite Boards",
      imgSrc: nonlite,
      points: [
        {
          head: "Traditional and Effective",
          text: "Suitable for indoor and outdoor use.",
        },
        {
          head: "Durable Materials",
          text: "Resistant to environmental factors.",
        },
        {
          head: "Customizable",
          text: "Various designs and finishes to match your needs.",
        },
        {
          head: "Cost-Effective",
          text: "Economical option for businesses looking for durable signage.",
        },
      ],
    },

    {
      title: "2D & 3D Letters",
      imgSrc: images,
      points: [
        {
          head: "Professional Appearance",
          text: "Adds depth and dimension to your signage.",
        },
        {
          head: "Versatile Materials",
          text: "Available in acrylic, metal, wood, and more.",
        },
        {
          head: "Precision Cut",
          text: "Ensures clean and accurate shapes for a polished look.",
        },
        {
          head: "Enhanced Visibility",
          text: "Stands out better than flat signs, increasing visibility.",
        },
      ],
    },
    {
      title: "Brass & Stainless Steel Etching Sign Boards",
      imgSrc: Brassstainless,
      points: [
        {
          head: "Elegant and Durable",
          text: "Perfect for professional and high-end signage.",
        },
        {
          head: "Detailed Designs",
          text: "Capable of intricate and precise etching.",
        },
        {
          head: "Long-Lasting",
          text: "Resistant to corrosion and environmental damage.",
        },
        {
          head: "Low Maintenance",
          text: "Requires minimal upkeep, maintaining its appearance over time.",
        },
      ],
    },
    {
      title: "Glow Sign Boards",
      imgSrc: GlowSignBoard,
      points: [
        {
          head: "Illuminated for Night Visibility",
          text: "Perfect for businesses that operate after dark.",
        },
        {
          head: "Durable Construction",
          text: "Resistant to weather and environmental damage.",
        },
        {
          head: "Custom Designs",
          text: "Tailored to fit your branding and message.",
        },
        {
          head: "Versatile Applications",
          text: "uitable for indoor and outdoor use.",
        },
      ],
    },
  ];
  const services = [
    { serviceName: "LED Sign Boards", serviceImage: ledsignscroll },
    { serviceName: "Non-Lite Boards", serviceImage: nonlitescroll },
    { serviceName: "2D & 3D Letters", serviceImage: twodscroll },
    {
      serviceName: "Brass & Stainless Steel Etching Sign Boards",
      serviceImage: Brassstainlessscroll,
    },
    { serviceName: "Glow Sign Boards", serviceImage: GlowSignBoardscroll },
  ];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>Sign Board Services in Bangalore | JR Signage</title>
        <meta
          name="description"
          content="Get top-quality Sign Board Services in Bangalore with JR Signage. Expert design, fabrication, and installation for all types of signage solutions."
        />
        <meta
          name="keywords"
          content="Sign board services Bangalore, custom signage Bangalore, 3D LED signs, neon signs, acrylic lettering, flex banners Bangalore, outdoor signage, indoor signage, branding signage, signage manufacturers Bangalore"
        />
      </Helmet>
      <ServiceHead />
      <Container className="mt-5">
        <Row>
          <Col>
            <ServiceScroll
              services={services}
              title="Our Sign-Board "
              customClass="homepage-service-scroll"
            />
          </Col>
        </Row>
        <CommonService serviceData={signBoardServiceData} serviceTitle="" />
      </Container>
    </>
  );
}

export default SigBoardService;
